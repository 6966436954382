import React from 'react';
import { Link } from 'gatsby';
import { MenuItemWrapper, MenuItem } from './navbar.style';

type MenuProps = {
	items: MenuItemsProps[];
	className?: string;
};

type MenuItemsProps = {
	url: string;
	label: string;
	external?: boolean;
	type?: string;
};

const Menu: React.FunctionComponent<MenuProps> = ({ items, className, ...props }) => {
	// Add all classs to an array
	const addAllClasses = [ 'menu' ];

	// className prop checking
	if (className) {
		addAllClasses.push(className);
	}

	return (
		<MenuItemWrapper className={addAllClasses.join(' ')} {...props}>
			{items.map((item, index) => (
				<MenuItem key={index}>
					{item.external ? item.type == 'button' ? (
						<a href={item.url} target='_blank'>
							<span style={{ color: '#834CFA' }}>{item.label}</span>
						</a>
					) : (
						<a href={item.url}>
							<span>{item.label}</span>
						</a>
					) : (
						<Link to={item.url} activeClassName='active-link'>
							<span>{item.label}</span>
						</Link>
					)}
				</MenuItem>
			))}
		</MenuItemWrapper>
	);
};

export default Menu;
